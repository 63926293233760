import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 要设置的全局变量
    // productList 设备信息
    productList: {
      proSD1901: {
        id: 'SD1901',
        name: '不锈钢无钥匙远程开锁智能井盖',
        // 设备小卡片介绍
        cardDetail: '根据城市通信人井（不局限于通信人井）分布特点，通过NB-IoT组网快速实现城市井盖智能实时云监测物联网系统。产品基于NB-IOT通讯传输技术，将人、井、云平台三方有效互联。终端实时采集井盖工作状态数据，对非法开启、异动等异常状态及时报警。手机APP/云平台可实时报告井盖状态，施工导航，无钥匙远程开锁，自动上锁，非法开启报警等功能...',
        // 设备小卡片的封面图片
        cardImg: require('../assets/productImg/SD19011.png'),
        // 设备简介 这是产品详情中的设备介绍
        productDetail: '根据城市通信人井（不局限于通信人井）分布特点，通过NB-IoT组网快速实现城市井盖智能实时云监测物联网系统。产品基于NB-IOT通讯传输技术，将人、井、云平台三方有效互联。终端实时采集井盖工作状态数据，对非法开启、异动等异常状态及时报警。手机APP/云平台可实时报告井盖状态，施工导航，无钥匙远程开锁，自动上锁，非法开启报警等功能。',
        // 设备参数 这是产品详情中的设备参数
        message: `
        ☆ 远程无钥匙开锁，自动上锁，磁感唤醒，手动按钮双唤醒，定制备用开锁<br />
        ☆ 非正常方式开锁报警，有害气体报警，液位超警戒报警，移位、倾斜报警<br />
        ☆ 定制化开发APP软件界面<br />
        `,
        // 厂商信息
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD1901',
          d: '网络制式：NB-IOT'
        }
      },
      proSD2001A: {
        id: 'SD2001A',
        name: '井盖智能监控终端（翻倾/移位）',
        cardDetail: '支持Coap, MQTT, LWM2M, TCP等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台。',
        cardImg: require('../assets/productImg/SD2001A1.png'),
        productDetail: '',
        message: `
        ☆ 支持Coap, MQTT, LWM2M, TCP等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台<br />
        ☆ 监测井盖翻倾、移位<br />
        ☆ 续航时间：3~5年（根据采样频率设定和报警发生频率有所不同）<br />
        ☆ 角加速度传感器、光通量传感器<br />
        ☆ 电源：锂电池/锂亚电池供电8000mAh<br />
        ☆ 网络频段：NBIOT<br />
        ☆ 待机工作电流：8uA，最大工作电流200uA<br />
        ☆ 防水等级：IP68<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD2020-A (仅井盖倾斜、移位）',
          d: '网络制式：NB-IOT'
        }
      },
      proSD2001B: {
        id: 'SD2001B',
        name: '管网智能监控终端（翻倾、水位、气体)',
        cardDetail: '支持Coap, MQTT, LWM2M, TCP等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台。',
        cardImg: require('../assets/productImg/SD2001B1.png'),
        productDetail: '',
        message: `
        ☆ 支持Coap, MQTT, LWM2M, TCP等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台<br />
        ☆ 监测井盖翻倾、水位超限、甲烷气体<br />
        ☆ 多协议<br />
        ☆ 续航时间：3年（根据采样频率设定和报警发生频率有所不同）<br />
        ☆ 多传感器接口<br />
        ☆ 电源：锂电池/锂亚电池供电8000mAh<br />
        ☆ 网络频段：NBIOT<br />
        ☆ 待机工作电流：8uA，最大工作电流200uA<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD2020-B (仅井盖倾斜）',
          d: '网络制式：NB-IOT'
        }
      },
      proSD2001C: {
        id: 'SD2001C',
        name: '无线智能垃圾桶监测终端',
        cardDetail: '支持Coap, MQTT, LWM2M,TCP 等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台。',
        cardImg: require('../assets/productImg/SD2001C1.png'),
        productDetail: '',
        message: `
        ☆ 支持Coap, MQTT, LWM2M, TCP等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台<br />
        ☆ 智慧垃圾桶解决方案城市垃圾桶开箱满桶报警物联网解决方案，监测是否扣盖，垃圾高度<br />
        ☆ 测量范围：0.2m~1m<br />
        ☆ 续航时间：6个月（根据采样频率设定和报警发生频率有所不同）<br />
        ☆ 超声波传感器、光通量<br />
        ☆ 电源：锂电池/锂亚电池供电8000mAh<br />
        ☆ 网络频段：NBIOT<br />
        ☆ 待机工作电流：8uA，最大工作电流200uA<br />
        ☆ IP65防护等级<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD2020-C',
          d: '网络制式：NB-IOT'
        }
      },
      proSD2001D: {
        id: 'SD2001D',
        name: '市政用无线智能温湿度监测终端',
        cardDetail: '支持Coap, MQTT, LWM2M,TCP 等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台。',
        cardImg: require('../assets/productImg/SD2001D1.png'),
        productDetail: '',
        message: `
        ☆ 支持Coap, MQTT, LWM2M, TCP等多协议的系统和物联网终端。直接对接电信天翼物联，中移物联的one-NET协议，京东物联，阿里云，华为云等平台<br />
        ☆ 智慧城市道路区域温度传感器，预警道路结冰、道路周边空气质量、道路照明监测<br />
        ☆ 续航时间：2年（根据采样频率设定和报警发生频率有所不同）<br />
        ☆ 湿度、温度、光通量传感器<br />
        ☆ 电源：锂电池/锂亚电池供电8000mAh<br />
        ☆ 网络频段：NBIOT<br />
        ☆ 待机工作电流：8uA，最大工作电流200uA<br />
        ☆ IP65防护等级<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD2001-D',
          d: '网络制式：NB-IOT'
        }
      },
      proSD1801: {
        id: 'SD1801',
        name: '地理式积水检测仪',
        cardDetail: '城市易积水点监测预警系统通过对隧道、涵洞等易积水路段的积水有无、积水深度的感知监测，采集数据无线传输至监测预警平台，实现公路隧道涵洞等路段积水预警预报，助力城市智慧化建设。',
        cardImg: require('../assets/productImg/jishuijiance.png'),
        productDetail: `
        积水检测传感器‌主要用于监测和检测水体积聚或积水情况，可以广泛应用于城市排水系统、洪水预警、建筑物防水等领域。其主要特点和功能包括水位测量、实时监测、高灵敏度、数据记录和传输、抗干扰能力以及警报功能。积水检测传感器能够测量水位高度并将其转化为电信号或数字数据，提供实时的积水监测和预警功能，同时能够抵抗环境中的干扰，如水质变化、温度变化等，并在水位超过预设阈值时触发警报‌
        `,
        message: `
        本系统将基于我公司智慧城市监测系统来研制，目前为西安排水集团专门定制研发。系统包含<br />
        ☆ 监测传感器<br />
        ☆ 服务器软件<br />
        ☆ APP软件<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD1801',
          d: '网络制式：GSM'
        }
      },
      proSD1902: {
        id: 'SD1902',
        name: '无线智能空气噪声环境监测仪',
        cardDetail: '电源：220V交流电源供电,网络频段：GSM/NBIOT/GPRS 900/1800 MHz，最大工作电流：1A',
        cardImg: require('../assets/productImg/SD19021.png'),
        productDetail: '',
        message: `
        ☆ 电源：220V交流电源供电<br />
        ☆ 网络频段：GSM/NBIOT/GPRS 900/1800 MHz<br />
        ☆ 最大工作电流：5秒/组<br />
        ☆ 外壳材质：铁质<br />
        ☆ 空气温度量程：-40~70℃<br />
        ☆ 空气湿度量程：0~100%<br />
        ☆ 风速量程：8个方位<br />
        ☆ 噪声量程：30~130db<br />
        ☆ PM量程：0~1000ug/m3<br />
        ☆ LED屏尺寸：54cm*102cm<br />
        ☆ 高度：2/3米立杆(可选)<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD1902A',
          d: '网络制式：GSM'
        }
      },
      proSD1902B: {
        id: 'SD1902B',
        name: '4G二沉池泥位显示大屏',
        cardDetail: '电源：12V直流电源供电,无线通信网络：4G CAT1,显示二沉池数量：1~4个,最大工作电流：1A',
        cardImg: require('../assets/productImg/SD1902B1.png'),
        productDetail: '',
        message: `
        ☆ 电源：12V直流电源供电<br />
        ☆ 无线通信网络<br />
        ☆ 显示二沉池数量：1~4个<br />
        ☆ 最大工作电流：1A<br />
        ☆ 数据上传频率：12秒/组<br />
        ☆ 外壳材质：贴纸<br />
        ☆ 空气温度量程: -40～70°C<br />
        ☆ LED屏尺寸: 54cm*102cm<br />
        ☆ 高度: 2/3米立杆(可选)<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD1902B',
          d: '网络制式：GSM'
        }
      },
      proSD20TSM01H: {
        id: 'SD20TSM01H',
        name: '水位、流量监测终端',
        cardDetail: '通过设备实时监测污水排放量和管道状态，及时发现问题并处理，保证水环境和水资源的安全数据精确，采用高精度的流量计和水位计，能够精确测量污水流量和管道水位，确保数据的准确性，操作简便。对城市可持续化化发展具有重要意义',
        cardImg: require('../assets/productImg/shuiweiliuliang.png'),
        productDetail: '控源截污采用智能井盖、雷达流量计、雷达水位计等监测设备，通过测量污水管道中的水位，流速和流量等参数，实现对污水排放量的实时监控。具体原理是利用流量计测量污水流经管道的体积和时间，计算出污水量，水位计则通过测量水位变化，实时反映污水排放量和管道状况',
        message: `
        ☆ 设备查询及管理、警情评估及通知、统计分析及曲线、数据查询及展示、数据通讯及采集<br />
        ☆ 数据库、G1S系统、数学模型等基础软件，服务器、工作站、大屏幕等硬件支撑<br />
        ☆ 有线与无线相结合的网络通讯环境<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD20TSM-01H',
          d: '网络制式：NBIOT/LoRa'
        }
      },
      proSD1800: {
        id: 'SD2201',
        name: '无线智能物联网污泥界面仪',
        cardDetail: 'SD2201污泥高度监测系统基于物联网技术，用于全天候监测和报警污水处理厂终沉池的污泥高度。沉池的泥位数据进行行传输和推送，以实现对泥位数据的监测',
        cardImg: require('../assets/productImg/wunishebei.png'),
        productDetail: '系统采用高精度光学元器件，将6组元器件间隔25cm密封放置于管中并固定于池坝一侧，仪器总长2m。底部位于水下距离池底2.5m处，并实现从2.5m ~ 4m高度10cm精度测量。设备将采集数据以无线wifi通讯传输至云端服务器，服务器按照特定算法进行数据处理，后利用污水浓度差对泥位进行拟合推算，达到误差更小的精确污泥高度。',
        message: `
        ☆ 泥位测量高度：水面下2米</br>
        ☆ 精度：0.03m</br>
        ☆ 响应时间：20s</br>
        ☆ 压力范围：≤0.3个大气压</br>
        ☆ 环境温度：-10℃ ~ 40℃</br>
        ☆ 通讯方式：无线wifi，广域网和局域网 
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD2201',
          d: '网络制式：GSM'
        }
      },
      proSD1903: {
        id: 'SD1903',
        name: '气田远程加注控制系统',
        cardDetail: '每口气井冬季生产消耗甲醇18万元；以陕西某气田2000口计算，3.6亿元甲醇防冻剂的生产消耗。该系统能够有效节约30%左右的甲醇的生产消耗；以上气田计算，一年节约1.2亿元的生产消耗。技术要点：4G无线通信、温度和压力仪表数据采集、PID控制、流量采集、阀门电控机构、小程序、嵌入式系统、云平台...',
        cardImg: require('../assets/productImg/SD19031.png'),
        productDetail: '',
        message: `
        ☆ 每口气井冬季生产消耗甲醇18万元；以陕西某气田2000口计算，3.6亿元甲醇防冻剂的生产消耗。<br />
        ☆ 该系统能够有效节约30%左右的甲醇的生产消耗；以上气田计算，一年节约1.2亿元的生产消耗<br />
        ☆ 技术要点：4G无线通信、温度和压力仪表数据采集、PID控制、流量采集、阀门电控机构、小程序、嵌入式系统、云平台<br />
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD1903',
          d: '网络制式：LTE'
        }
      },
      proSD20241: {
        id: 'SD20241',
        name: '智慧城市监测系统',
        cardDetail: '智慧城市监测系统已经为西安，广州等多地提供信息服务，通过对设备的实时监控，达到精准掌握设备数据的目的。平台以监测设备状态为主，提供查看设备数据、推送报警信息、管理设备类型、管理人员权限等',
        cardImg: require('../assets/productImg/jinggaipingtai.png'),
        productDetail: '',
        message: `
        
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '设备型号: SD1903',
          d: ''
        }
      },
      proSDxcx: {
        id: 'SDxcx',
        name: '智能井盖小程序',
        cardDetail: '智能井盖小程序结合终端以及物联网平台，为用户提供了移动端便捷操作方式，采用了实时通讯功能，抓取设备状态，用户可以不再使用电脑，即可查看设备信息，极大的提高了用户对于物联网设备信息获取的便利性。',
        cardImg: require('../assets/productImg/xiaochengxu1.png'),
        productDetail: '智能井盖小程序采用实时通讯模式，管理设备属性状态，设备位置信息，设备种类信息，以及人员权限等。',
        message: `
        
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '软件版本: v1.15.6',
          d: ''
        }
      },
      proSDwuni: {
        id: 'SDwuni',
        name: '污泥界面仪管理系统',
        cardDetail: '污泥界面仪管理系统已经为西安，汉中等地提供信息服务，通过对设备的实时监控，达到精准掌握设备数据的目的。平台以监测设备状态为主，提供查看设备数据、管理人员权限等',
        cardImg: require('../assets/productImg/wunipingtai.png'),
        productDetail: '',
        message: `
        ☆ 客户端：Windows XP以上系统的PC机系统适用</br>
        ☆ 服务器端：阿里云服务器，linux server
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '软件版本: v1.14.8',
          d: ''
        }
      },
      proSDwunixcx: {
        id: 'SDwunixcx',
        name: 'Sewage的污泥监测小程序',
        cardDetail: '污泥监测小程序结合终端以及物联网平台，为用户提供了移动端便捷操作方式，采用了实时通讯功能，抓取设备状态，用户可以不再使用电脑，即可查看设备信息，极大的提高了用户对于物联网设备信息获取的便利性。',
        cardImg: require('../assets/productImg/wunixcx1.png'),
        productDetail: '污泥监测小程序用于监测污泥界面仪设备数据，进行现场清洗设备操作，统计设备历史数据等功能',
        message: `
        
        `,
        manufacturer: {
          a: '品牌：圣豆电子',
          b: '厂商名称：西安圣豆电子信息技术有限公司',
          c: '软件版本: v1.15.6',
          d: ''
        }
      },
    }
  },
  getters: {
    // get方法 获取变量
    getproductlist(state) {
      return state.productList
    }
  },
  mutations: {
    // set方法 修改变量
    setDevicelist(state, value) {
      state.productList = value
    }
  }
})
